var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "contact-page"
  }, [_vm._m(0), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row section-b-space"
  }, [_c('div', {
    staticClass: "col-lg-7"
  }, [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v("Teka Help Center")]), _c('div', {
    staticClass: "contact-right"
  }, [_c('ul', [_vm._m(1), _vm._m(2), _c('li', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "icon-side-contact"
  }, [_c('b-icon-geo-alt', {
    attrs: {
      "variant": "danger"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Address")])], 1), _vm._m(3)]), _c('li', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "icon-side-contact"
  }, [_c('b-icon-hand-thumbs-up', {
    attrs: {
      "variant": "danger"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Follow us")])], 1), _vm._m(4)])])])]), _c('div', {
    staticClass: "col-lg-5 mt-4"
  }, [_c('div', {
    staticClass: "contact-page-form",
    attrs: {
      "method": "post"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Name*"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Email*"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Phone*"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Address*"
    },
    model: {
      value: _vm.address,
      callback: function callback($$v) {
        _vm.address = $$v;
      },
      expression: "address"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Company*"
    },
    model: {
      value: _vm.company,
      callback: function callback($$v) {
        _vm.company = $$v;
      },
      expression: "company"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": "Message*",
      "rows": "1"
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-primary",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.sendMail
    }
  }, [_vm._v("Submit")])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('h2', {
    staticClass: "font-weight-bold mb-3"
  }, [_vm._v("Contact Us")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" We are headquartered in Hyderabad and are expanding our presence in different cities. ")]), _c('p', [_vm._v(" Each regional office address will be added as we started opening. ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "icon-side-contact"
  }, [_c('img', {
    attrs: {
      "src": "img/contact/phone.png",
      "alt": "contact icon"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Contact Us")])]), _c('div', {
    staticClass: "detail-side-contact"
  }, [_c('p', {
    staticClass: "mb-1"
  }, [_vm._v("+91 - 40 - 2332 6565/9955")]), _c('p', [_vm._v("+91 6305920774")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "icon-side-contact"
  }, [_c('img', {
    attrs: {
      "src": "img/contact/email.png",
      "alt": "email icon"
    }
  }), _c('h6', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Email")])]), _c('div', {
    staticClass: "detail-side-contact"
  }, [_c('p', {
    staticClass: "mt-2"
  }, [_vm._v("support@teka.net.in")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-side-contact"
  }, [_c('p', {
    staticClass: "mb-1"
  }, [_vm._v("Vedhaank Digital Private Limited")]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v("Plot No.: 6-3-1090/A/3/4 (SF)")]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v("3rd Floor, KRB Towers, Somajiguda,")]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v("Hyderabad - 500082. T.S. India")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-side-contact"
  }, [_c('span', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.facebook.com/TekaTime-105852398754087"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/facebook.png",
      "alt": "facebook icon"
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.instagram.com/tekatime/"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/instagram.png",
      "alt": "instagram icon"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://www.linkedin.com/in/teka-time-524539236/",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/linkedin.png",
      "alt": "linkedin icon"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://twitter.com/teka_time",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/footer/twitter.png",
      "alt": "twitter icon"
    }
  })])])]);
}]

export { render, staticRenderFns }