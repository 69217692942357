<template>
  <div>
    <section class="contact-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="font-weight-bold mb-3">Contact Us</h2>
            <p class="mb-2">
              We are headquartered in Hyderabad and are expanding our presence
              in different cities.
            </p>
            <p>
              Each regional office address will be added as we started opening.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row section-b-space">
          <div class="col-lg-7">
            <h4 class="mb-4">Teka Help Center</h4>
            <div class="contact-right">
              <ul>
                <li class="d-flex">
                  <div class="icon-side-contact">
                    <img src="img/contact/phone.png" alt="contact icon" />
                    <h6 class="font-weight-bold">Contact Us</h6>
                  </div>
                  <div class="detail-side-contact">
                    <p class="mb-1">+91 - 40 - 2332 6565/9955</p>
                    <p>+91 6305920774</p>
                  </div>
                </li>
                <li class="d-flex">
                  <div class="icon-side-contact">
                    <img src="img/contact/email.png" alt="email icon" />
                    <h6 class="font-weight-bold">Email</h6>
                  </div>
                  <div class="detail-side-contact">
                    <p class="mt-2">support@teka.net.in</p>
                  </div>
                </li>
                <li class="d-flex">
                  <div class="icon-side-contact">
                    <b-icon-geo-alt variant="danger"></b-icon-geo-alt>
                    <h6 class="font-weight-bold">Address</h6>
                  </div>
                  <div class="detail-side-contact">
                    <p class="mb-1">Vedhaank Digital Private Limited</p>
                    <p class="mb-1">Plot No.: 6-3-1090/A/3/4 (SF)</p>
                    <p class="mb-1">3rd Floor, KRB Towers, Somajiguda,</p>
                    <p class="mb-1">Hyderabad - 500082. T.S. India</p>
                  </div>
                </li>
                <li class="d-flex">
                  <div class="icon-side-contact">
                    <b-icon-hand-thumbs-up
                      variant="danger"
                    ></b-icon-hand-thumbs-up>
                    <h6 class="font-weight-bold">Follow us</h6>
                  </div>
                  <div class="detail-side-contact">
                    <span>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/TekaTime-105852398754087"
                      >
                        <img
                          src="img/footer/facebook.png"
                          alt="facebook icon"
                        />
                      </a>

                      <a
                        target="_blank"
                        href="https://www.instagram.com/tekatime/"
                      >
                        <img
                          src="img/footer/instagram.png"
                          class=""
                          alt="instagram icon"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/teka-time-524539236/"
                        target="_blank"
                      >
                        <img
                          src="img/footer/linkedin.png"
                          alt="linkedin icon"
                        />
                      </a>
                      <a href="https://twitter.com/teka_time" target="_blank">
                        <img src="img/footer/twitter.png" alt="twitter icon" />
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-5 mt-4">
            <div class="contact-page-form" method="post">
              <div class="form-group">
                <v-text-field placeholder="Name*" v-model="name"></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  placeholder="Email*"
                  v-model="email"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  placeholder="Phone*"
                  v-model="phone"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  placeholder="Address*"
                  v-model="address"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  placeholder="Company*"
                  v-model="company"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-textarea
                  placeholder="Message*"
                  rows="1"
                  v-model="message"
                ></v-textarea>
              </div>
              <b-button type="submit" class="btn-primary" @click="sendMail"
                >Submit</b-button
              >
            </div>
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3053107802684!2d78.35052161454755!3d17.492933204357602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9268559aaaab%3A0x730f614f2bb1a3e7!2sTrigital%20Technologies!5e0!3m2!1sen!2sin!4v1631810863084!5m2!1sen!2sin"
              width="600" height="450" frameborder="0" class="location-map" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
      company: "",
      message: "",
    };
  },
  methods: {
    async sendMail() {
      let payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
        company: this.company,
        message: this.message,
      };
      console.log(payload);
      await this.$store.dispatch("contact/getContactEnquiry", payload);
      this.$swal({
        text: "Sent",
        timer: 1100,
      });
      this.name = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.company = "";
      this.message = "";
    },
  },
};
</script>
<style scoped>
.contact-page-form {
  border: 1px solid #ddd;
  box-shadow: 1px 2px 10px 1px #ddd;
  padding: 20px;
  border-radius: 10px;
}
ul {
  list-style-type: none;
}
.location-map {
  border: 0;
  height: 350px;
  margin-left: 32px;
  margin-bottom: 100px;
}
.detail-side-contact {
  margin-left: 15px;
}
.icon-side-contact {
  text-align: center;
  padding: 0 15px 0 0;
  border-right: 1px solid #ddd;
  width: 130px;
}
.contact-right li {
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-top: 15px;
}
</style>
